import React from "react";
import Button from "antd/es/button";
import Select from "antd/es/select";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { range } from "underscore";
import { monthFilters } from "./constants";
import { Period } from "./utils";

const { Option } = Select;

const dayjs = require("dayjs");

dayjs().format();

function PeriodSelect({ period, onSelect, onCheck, contract }) {
  const onPrev = () => onSelect(period.prev());
  const onNext = () => onSelect(period.next());

  let prevAvailable;
  let nextAvailable;
  if (contract && contract.settlement_period === "QUARTERLY") {
    prevAvailable = false;
    nextAvailable = false;
  } else {
    prevAvailable = onCheck(period.prev());
    nextAvailable = onCheck(period.next());
  }

  const month =
    contract && contract.settlement_period === "QUARTERLY"
      ? "Q2"
      : period.month.month;

  function renderMonthFilter() {
    if (contract && contract.settlement_period === "QUARTERLY") {
      return ["Q2"].map((quarter) => <Option key={quarter}>{quarter}</Option>);
    }

    return monthFilters.map((month) => (
      <Option key={month.id}>{month.month}</Option>
    ));
  }

  function renderYearFilter() {
    if (contract && contract.settlement_period === "QUARTERLY") {
      return [2024].map((year) => <Option key={year}>{year}</Option>);
    }

    return range(2016, dayjs().year() + 2).map((year) => (
      <Option key={year}>{year}</Option>
    ));
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: 20,
      }}
    >
      <Button onClick={onPrev} disabled={!prevAvailable}>
        <LeftOutlined />
      </Button>
      <Select
        value={period.year}
        style={{ width: 120 }}
        onChange={(year) => onSelect(new Period(period.month, year))}
      >
        {renderYearFilter()}
      </Select>
      <Select
        value={month}
        style={{ width: 120 }}
        onChange={(month) =>
          onSelect(
            new Period(
              monthFilters.find((mf) => String(mf.id) === month),
              period.year
            )
          )
        }
      >
        {renderMonthFilter()}
      </Select>
      <Button onClick={onNext} disabled={!nextAvailable}>
        <RightOutlined />
      </Button>
    </div>
  );
}

export default PeriodSelect;
